import { React, View, Text, StyleSheet, Image, Link, useState, Button } from 'lib'
import { PageTitle } from 'components'
import { Theme } from 'app'
import BgImage8 from 'assets/landing-bg/8-map.jpg'
import { uniqBy } from 'lodash'

const Article = (props) => {
  const image = props.image.childImageSharp.fluid.src as string
  const separatedImageName = image.split('/')
  const alt = separatedImageName[separatedImageName.length - 1]
  return (
    <Link to={props.slug} openNewTab={!props?.isBlog} style={styles.item}>
      <View variant={'fullWidth flex'} style={styles.itemWrapper}>
        <View style={styles.imageWrapper}>

          <Image
            source={image}
            style={[styles.image, props?.isBlog && styles.blogImage]} alt={image.split}
          />
          <Image source={BgImage8} style={styles.bgImage} alt={alt}/>
        </View>

        <View variant={'column'} style={styles.contentWrapper}>
          <Text variant={'p3 greyscale3'} text={props.date}/>
          <Text variant={'h4 marginTop:2 marginBottom:1'} text={props.title}/>
          <Text variant={'p3'} style={styles.description} text={props.description}/>
        </View>
      </View>
    </Link>
  )
}

function Articles({ articles }) {
  const [expanded, setExpanded] = useState(false)

  const uniqueArticles = uniqBy(articles, 'frontmatter.title')

  const displayArticles = expanded ? uniqueArticles : uniqueArticles?.slice(0, 4)

  return (
    <>
      <PageTitle location='News' title='The Latest Articles & Updates'/>
      <View style={styles.wrapper}>
        {displayArticles?.map((data) => <Article key={data.frontmatter.slug} {...data.frontmatter}/>)}
      </View>
      <Button
        text={`Show ${expanded ? 'less' : 'more'}`}
        variant={'uppercase'}
        style={styles.linkButton}
        textStyle={styles.linkButtonText}
        onPress={() => setExpanded(!expanded)}/>
    </>
  )
}

export default Articles

const styles = StyleSheet.createComponentStyleSheet({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: Theme.spacing(3),
    overflow: 'hidden',
  },
  item: {
    margin: '0 !important',
    textDecoration: 'none !important',
  },
  itemWrapper: {
    minHeight: 220,
    borderRadius: Theme.spacing(2.3),
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: Theme.colors.greyscale5,
    overflow: 'hidden',
    [Theme.media.down('small')]: {
      flexDirection: 'column',
    },
  },
  imageWrapper: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    flexShrink: 0,
    overflow: 'hidden',
    [Theme.media.up('small')]: {
      maxWidth: 220,
    },
    [Theme.media.down('small')]: {
      minHeight: 330,
    },
  },
  image: {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
    position: 'absolute',
    zIndex: 3,
    margin: 'auto 0',
    top: 0,
    bottom: 0,
    // background: 'red',
    paddingLeft: Theme.spacing(2),
    paddingRight: Theme.spacing(2),
  },
  blogImage: {
    height: '100%',
    paddingLeft: 0,
    paddingRight: 0,
    // [Theme.media.down('small')]: {
    // },
    [Theme.media.up('xlarge')]: {
      height: '100%',
    },
  },
  bgImage: {
    position: 'absolute',
    // HACK to fix blur from appearing weird at the corners, since it blurs just white space
    width: '106%',
    height: '106%',
    top: '-3%',
    bottom: '-3%',
    left: '-3%',
    right: '-3%',
    objectFit: 'cover',
    filter: 'blur(2px)',
  },
  contentWrapper: {
    padding: Theme.spacing(3),
  },
  description: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box !important',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'normal',
    [Theme.media.down('small')]: {
      WebkitLineClamp: 4,
    },
  },
  linkButton: {
    alignSelf: 'center',
    width: 'fit-content',
    textDecorationLine: 'none !important',
  },
  linkButtonText: {
    color: Theme.colors.white,
  },
})
